import type { GISPlace } from '~/types/api'

export default () => {
  const geolocation = useGeolocation()

  return {
    name: [geolocation.city, geolocation.regionCode].filter((v) => v).join(', '),
    address: [geolocation.city, geolocation.regionCode, geolocation.country].filter((v) => v).join(', '),
    city: geolocation.city,
    coordinates: {
      lat: geolocation.latitude,
      lng: geolocation.longitude
    },
    country: geolocation.country,
    countryCode: geolocation.country,
    postalCode: geolocation.postalCode,
    region: geolocation.regionCode,
    regionCode: geolocation.regionCode,
    placeId: null,
    type: 'Other',
    bounds: undefined
  } as GISPlace
}
